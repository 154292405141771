/* eslint-disable */
import React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/Layout"
import SEO from "../../components/seo"
import SearchResults from "../../components/Search"
import "../../styles/page-search-results.scss"

export default ({
  location: { search },
  data: {
    publicResources: { nodes: publicResources },
    newsDescendingOrder: { nodes: newsDescendingOrder },
  },
}) => (
  <Layout fluid={true} pageInfo={{ pageName: "Search Results" }}>
    <SEO
      title="Search Results | First Responders"
      keywords={[`gatsby`, `react`, `bootstrap`]}
    />
    <SearchResults
      cache={{
        resources: publicResources,
        news: newsDescendingOrder,
      }}
      query={decodeURIComponent(search.replace("?q=", ""))}
    />
  </Layout>
)

export const ResourceQuery = graphql`
  query SearchContent {
    publicResources: allNodePublicResource(
      sort: { order: DESC, fields: [created] }
    ) {
      nodes {
        id
        title
        field_vimeo_id
        link: field_link {
          uri
        }
      }
    }
    newsDescendingOrder: allNodeNews(sort: { order: DESC, fields: [created] }) {
      nodes {
        id
        title
        link: field_link {
          uri
        }
        relationships {
          field_image {
            relationships {
              field_media_image {
                thumbnail: localFile {
                  childImageSharp {
                    fixed {
                      src
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
